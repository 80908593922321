<template>
  <v-row>
    <v-col cols="12" md="3">
      <v-text-field label="Nome"
                    dense
                    v-model="value.nome"
                    :rules="[
                        v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 100),
                    ]"/>
    </v-col>
    <v-col cols="12" md="3">
      <lookup-dominio label="Sexo"
                      v-model="value.sexo"
                      :hidden-clear="false"
                      hint="Selecione o sexo do cliente"
                      :type="lookups.sexo"/>
    </v-col>
    <v-col cols="12" md="3">
      <lookup-dominio label="Só Matriculados"
                      v-model="value.soMatriculados"
                      :type="lookups.soMatriculados"
                      :hidden-clear="false"/>
    </v-col>
    <v-col cols="12" md="3">
      <lookup-dominio label="Exibir Local"
                      v-model="value.exibirLC"
                      :type="lookups.exibirLC"
                      :hidden-clear="false"/>
    </v-col>
  </v-row>
</template>

<script>

import {ItemDeDominio} from "../../../../api/itemsDominio";
import {findAll as findAllLocal} from "../../../../api/locais";
import LookupDominio from "../../common/LookupDominio";
import OrderInput from "../../common/OrderInput";
import SearchBox from "../../common/SearchBox";

export default {
  name: "RelatorioClienteForm",
  components: {
    SearchBox,
    LookupDominio,
    OrderInput
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      lookups: {
        sexo: ItemDeDominio.ARR_SEXO,
        status: ItemDeDominio.STATUS_PADRAO,
        soMatriculados: ItemDeDominio.FLG_PADRAO,
        exibirLC: ItemDeDominio.FLG_PADRAO,
      },
      loaderLocal(page, search) {
        return findAllLocal(page, {
          descricao: search
        })
      },
    }
  },
  computed: {
    local() {
      if (this.value.idLocal == null) return null;
      return {
        id: this.value.idLocal,
        descricao: this.value.descricao_idLocal
      }
    },
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}


</style>